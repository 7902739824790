/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Action, Getter, Mutation, State } from 'vuex-class'
import { Shop } from '@/store/shop/types';
const namespace: string = 'shop';

export default class ShopDeliveryPage extends Vue {
	@State('shop') shop: Shop | undefined;
	@Mutation('setPaymentOrder', {namespace}) setPaymentOrder: any;
	@Action('fetchCountry', {namespace}) fetchCountry: any;
	@Getter('getDeliveryCountry', {namespace}) getDeliveryCountry: any;

	isChecked: boolean = false;
	country: string = '';

	choiceCountry(e: Event) {
		this.isChecked = !this.isChecked;
		const {target} = e;
		this.country = (target as HTMLDivElement).innerText;
		this.setPaymentOrder({
			country: this.country
		});
	}
	mounted() {
		this.fetchCountry();
	}
}
